<template>
    <Card>
        <template #content>
            <h6 class="text-center">
                Already have an account? Sign in below.
            </h6>
            <div v-if="errorMessage" class="text-center text-red-500 font-semibold py-2">{{ errorMessage }}</div>
            <GoogleAuth ref="googleAuthRef" @clearTabMessages="clearTabMessages"></GoogleAuth>
            <div class="divider">
                <span>or</span>
            </div>
            <Form class="grid grid-cols-1 gap-2 mt-2" @submit="submit" :validation-schema="schema" mode="eager">
                <InputField id="loginEmail" label="Email" name="email" type="email" placeholder="Email" />
                <InputField id="loginPassword" label="Password" name="password" type="password" placeholder="Password" />
                <div class="flex justify-center">
                    <p class="text-xs text-center mt-2">
                        <a class="text-[#0070b7] hover:font-bold" :href=forgotPasswordURL target="_blank">Forgot
                            Password?</a>
                    </p>
                </div>
                <Button label="Login" :loading="loading" icon="pi pi-sign-in" type="submit"
                    class="w-fit border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] ml-auto">

                </Button>
            </Form>
            <p class="text-xs text-center mt-4">
                You can send us your feedback <a class="text-[#0070b7] hover:font-bold"
                    href="https://poshpopassistant.com/contact-us" target="_blank">here</a>
            </p>
        </template>
    </Card>
</template>

<script setup lang="ts">
import Card from 'primevue/card';
import InputField from './InputField.vue';
import GoogleAuth from './GoogleAuth.vue';
import Button from 'primevue/button';
import * as yup from 'yup';
import { Form } from 'vee-validate';
import { useAuthStore } from '../stores/auth';
import { loginApi } from '@/services/auth';
import { onMounted, ref } from 'vue';
import { handleServerError, log } from '@/utils/index';
import { serverDirectUrl } from '@/globals/env';
import * as Sentry from '@sentry/vue';

const loading = ref(false);
const authStore = useAuthStore();
const errorMessage = ref();
const googleAuthRef = ref();
const forgotPasswordURL = ref(serverDirectUrl + 'password/reset')

const schema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required().min(8),
});

onMounted(() => {
    errorMessage.value = null
    loading.value = false
})

const clearTabMessages = () => {
    errorMessage.value = null;
}

const submit = async (_event: any) => {
    loading.value = true;
    errorMessage.value = null;
    googleAuthRef.value.clearMessages()

    try {
        const res = await loginApi({ email: _event.email, password: _event.password });
        if (res.status == 200) {
            await authStore.updateUserData(res.json);
        } else {
            if (res?.json?.error) {
                handleServerError(res?.json?.error)
            }
            throw new Error("Failed to login")
        }
    }
    catch (err) {
        log(err);
        Sentry.captureException(err);
        if (typeof err === "string")
            errorMessage.value = "Failed to login: " + err;
        else if (err instanceof Error)
            errorMessage.value = "Failed to login: " + err.message;

    } finally {
        loading.value = false;
    }
}

</script>

<style>
.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
    margin-right: .25em;
}

.divider:not(:empty)::after {
    margin-left: .25em;
}
</style>