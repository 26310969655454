<template>
  <div>
    <Dialog header="Welcome!" :position="position" v-model:visible="showTour" :draggable="false" modal>
      <p>Welcome to PoshPop! Let's set things up.</p>

      <div class="flex justify-end gap-2 pt-4">
        <Button type="button" label="Next" severity="secondary" @click="nextStep"></Button>
      </div>
    </Dialog>
    <Dialog header="Pin the Extension" :position="position" v-model:visible="showPinExtension" :draggable="false" modal>
      <p>To pin the extension, click on the puzzle icon in the Chrome toolbar, then click the pin icon next to our
        extension.</p>
      <div class="flex justify-center gap-2 pt-4">
        <img :src=howToPinGif alt="Pin Extension GIF" style="width:100%; max-width:400px;" />
      </div>
      <div class="flex justify-end gap-2 pt-4">
        <Button type="button" label="Next" severity="secondary" @click="nextStep"></Button>
      </div>
    </Dialog>
    <Dialog header="Keep Poshmark Active" :position="position" v-model:visible="showKeepActive" :draggable="false" modal>
      <p>To keep Poshmark always active, go to Chrome settings &gt; Performance &gt; Always keep these sites active, and
        add "poshmark.com".</p>
      <div class="flex justify-center gap-2 pt-4 relative  cursor-pointer" @click="openInNewTab(howToPersistantGif)">
        <img :src=howToPersistantGif alt="Keep Poshmark active" style="width:100%; max-width:400px;" />
        <div class="absolute bottom-2 left-2 flex items-center space-x-1 text-black font-bold">
          <i class="pi pi-external-link"></i>
          <span class="text-xs">show in full screen</span>
        </div>
      </div>
      <div class="flex justify-end gap-2 pt-4">
        <Button type="button" label="Next" severity="secondary" @click="nextStep"></Button>
      </div>
      <!-- <div class="flex justify-end gap-2 pt-4">
        <Button type="button" label="Finish" severity="secondary" @click="endTour"></Button>
      </div> -->
    </Dialog>
    <Dialog header="How to Check if PoshPop Is Running" :position="position" v-model:visible="showAdditionalFeatures"
      :draggable="false" modal>
      <p> PoshPop no longer needs to manually scroll through your closet. Simply look for the green “Active” label to
        ensure a task is running.</p>
      <div class="flex justify-center gap-2 pt-4">
        <img :src="additionalFeaturesImage" alt="Additional Features Image" style="width:100%; max-width:400px;"
          class="mb-2" />
      </div>

      <div class="flex justify-end gap-2 pt-4">
        <Button type="button" label="Finish" severity="secondary" @click="endTour"></Button>
      </div>
    </Dialog>
  </div>
</template>
  
<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Button from "primevue/button";
import Dialog from 'primevue/dialog';

const howToPinGif = "/how-to-pin.gif"
const howToPersistantGif = "/keep-site-active.gif"
const additionalFeaturesImage = "/active-label-screenshot.png"
const showTour = ref(false);
const showPinExtension = ref(false);
const showKeepActive = ref(false);
const showAdditionalFeatures = ref(false);
const position = ref<"topright" | "center" | "top" | "bottom" | "left" | "right" | "topleft" | "bottomleft" | "bottomright" | undefined>('topright');

const nextStep = () => {
  if (showTour.value) {
    showTour.value = false;
    showPinExtension.value = true;
  } else if (showPinExtension.value) {
    showPinExtension.value = false;
    showKeepActive.value = true;
  } else if (showKeepActive.value) {
    showKeepActive.value = false;
    showAdditionalFeatures.value = true;
  }
};

const endTour = () => {
  showAdditionalFeatures.value = false;
}

onMounted(() => {
  //reset listeners to prevent duplicate
  window.addEventListener('message', messagesHandler);
});

onUnmounted(() => {
  //reset listeners to prevent duplicate listeners
  window.removeEventListener('message', messagesHandler);
})

//This will handle messages sent from the extension to the web server
const messagesHandler = (event: any) => {
  if (event.data.message == "extension-installed") {
    //start tour
    showTour.value = true
  }
}

const openInNewTab = (url: string) => {
  window.open(url, '_blank');
}

</script>
  
<style>
/* Add any necessary styling */
</style>