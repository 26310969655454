<template>
    <div class="flex items-center gap-3 my-2">
        <Button
            class="border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] "
            @click="$router.replace({ name: 'HomeTab' })">
            Back
        </Button>
        <Tag v-if="isActive" severity="success" value="Active"></Tag>
    </div>
    <Message class="p-0 my-1 bg-[#F0FDF4] bg-opacity-95" :pt="{ icon: { class: 'custom-message-icon' } }" severity="success"
        icon="pi pi-check-circle text-base" v-if="isActive && !hideSettingsWarning" :closable="false">
        <strong>Note:</strong> Actions won't appear on screen, but if it says "Active", your task is scheduled or running.
        <br /><br />
        To update the settings, stop the task first, then restart it to apply the new settings.
        <div @click="hideMessage" class="flex underline mt-2 text-blue-500 justify-end cursor-pointer text-sm">Don't show
            this again</div>
    </Message>
</template>

<script setup lang="ts">
import Tag from 'primevue/tag';
import Button from "primevue/button";
import Message from 'primevue/message';
import { onMounted, ref } from 'vue';
import * as Sentry from '@sentry/vue';
import LocalStorageWrapper from '@/classes/LocalStorageWrapper';

defineProps<{
    isActive: boolean,
    isScheduled?: boolean
}>()

const localStorageWrapper = LocalStorageWrapper.getInstance()
const hideSettingsWarning = ref<boolean>(false)

onMounted(async () => {
    try {
        const storedData = await localStorageWrapper.get('hideSettingsWarning')
        hideSettingsWarning.value = false//!!JSON.parse(storedData as string)
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }
})

const hideMessage = () => {
    try {
        localStorageWrapper.set('hideSettingsWarning', JSON.stringify(true))
        hideSettingsWarning.value = true
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }
}
</script>

<style>
.custom-message-icon {
    align-self: flex-start;
}
</style>
