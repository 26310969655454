import { serverUrl } from '@/globals/env'
import { sendMessage, log } from '@/utils/index'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import * as Sentry from '@sentry/vue'
import LocalStorageWrapper from '@/classes/LocalStorageWrapper'
import { syncSession } from '@/services/auth'

export const useAuthStore = defineStore('Auth', () => {
  //TODO: add type User
  const user = ref()
  const loggedIn = ref(false)
  const referralCode = ref()
  const localStorageWrapper = LocalStorageWrapper.getInstance()

  const initStore = async () => {
    try {
      //user.value = JSON.parse(localStorage.getItem('poshpopUser') as string)
      const poshpopUser = await localStorageWrapper.get('poshpopUser')

      if (poshpopUser) {
        user.value = JSON.parse(poshpopUser as string)
        log(user.value)
        loggedIn.value = user.value ? true : false
        referralCode.value = user.value?.user?.referral_code
        //sync current user session on first launch
        await syncSession()
        notifyUserChangedToExtension()
      }
    } catch (err) {
      Sentry.captureException('Failed to access inital auth store: ' + err)
      log('Failed to access inital auth store: ' + err)
    }
  }

  initStore()

  const updateUserData = async (data: any) => {
    localStorageWrapper.set('poshpopUser', JSON.stringify(data))
    // localStorage.removeItem('poshpopUser')
    // localStorage.setItem('poshpopUser', JSON.stringify(data))
    user.value = data
    log(user.value)
    referralCode.value = user.value?.user?.referral_code
    loggedIn.value = true
    notifyUserChangedToExtension()
  }

  const destroyUserData = async () => {
    localStorageWrapper.remove('poshpopUser')
    user.value = null
    loggedIn.value = false
    notifyUserChangedToExtension()
  }

  const updateReferralCode = async (code: string) => {
    const poshpopUser = await localStorageWrapper.get('poshpopUser')
    const savedData = JSON.parse(poshpopUser as string)
    savedData.referral_code = code
    referralCode.value = code
    localStorageWrapper.set('poshpopUser', JSON.stringify(savedData))
    user.value = savedData
    notifyUserChangedToExtension()
  }

  const refreshToken = async () => {
    try {
      const response = await fetch(serverUrl + 'refresh-token', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.value.token}`,
          'content-type': 'application/json'
        }
      })

      const data = await response.json()

      if (data.error) throw new Error(`Failed to refresh token: ${data.error}`)

      if (!data.token) throw new Error(`Failed to refresh token.`)

      const newToken = data.token
      await setToken(newToken)
    } catch (err) {
      //failed to refresh token, end session
      destroyUserData()
      if (err instanceof Error) throw err
      throw new Error(`Failed to refresh token: ${err}`)
    }
  }

  const setToken = async (token: string) => {
    if (user.value) user.value.token = token
    localStorageWrapper.set('poshpopUser', JSON.stringify(user.value))
    // localStorage.removeItem('poshpopUser')
    // localStorage.setItem('poshpopUser', JSON.stringify(user.value))
    notifyUserChangedToExtension()
  }

  const hasActivePlan = () => {
    //check if active plan
    if (user.value && user.value.subscription && !user.value.subscription.ended_at) return true

    //check if free trial
    if (user.value && Date.parse(user.value.user?.trial_ends_at) > Date.now()) return true

    return false
  }

  const notifyUserChangedToExtension = () => {
    sendMessage({
      message: 'auth-user',
      user: user.value ? JSON.parse(JSON.stringify(user.value.user)) : null,
      hasActivePlan: hasActivePlan()
    })
  }

  window.addEventListener('message', function (event) {
    if (event.data.message == 'getUserToken') {
      log(`getUserToken called`)
      window.parent.postMessage(
        { message: 'getUserTokenResponse', userToken: user?.value?.token },
        '*'
      )
    }
  })

  return {
    loggedIn,
    user,
    referralCode,
    updateUserData,
    destroyUserData,
    updateReferralCode,
    refreshToken,
    hasActivePlan
  }
})
