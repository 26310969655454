<template>
    <Card>
        <template #content>
            <h6 class="text-center">
                Create an account and get your free trial. <br /><strong>(No credit card required)</strong>
            </h6>
            <div v-if="errorMessage" class="text-center text-red-500 font-semibold py-2">{{ errorMessage }}</div>
            <GoogleAuth ref="googleAuthRef" @clearTabMessages="clearTabMessages"></GoogleAuth>
            <div class="divider">
                <span>or</span>
            </div>
            <Form class="grid grid-cols-1 gap-2 mt-2" @submit="submit" :validation-schema="schema">
                <InputField label="Name" name="name" type="email" placeholder="Name..." />
                <InputField label="Email" name="email" type="email" placeholder="Email..." />
                <InputField label="Password" name="password" type="password" placeholder="Password" />
                <InputField label="Password Confirmation" name="password_confirmation" type="password"
                    placeholder="Password Confirmation" />
                <Button :loading="loading" label="Register" icon="pi pi-user-plus" type="submit"
                    class="w-fit border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] ml-auto"></Button>
            </Form>
            <p class="text-xs text-center mt-4">
                By signing up you agree to PoshPop's <a href="https://poshpopassistant.com/terms-of-service"
                    class="text-[#0070b7] hover:font-bold" target="_blank"> Terms of Service</a> and <a
                    href="https://poshpopassistant.com/privacy-policy" class="text-[#0070b7] hover:font-bold"
                    target="_blank">Privacy Policy</a>, and agree to receive marketing communications from PoshPop at the
                email address provided.
            </p>
        </template>
    </Card>
</template>

<script setup lang="ts">
import Card from 'primevue/card';
import Button from 'primevue/button';
import InputField from './InputField.vue';
import GoogleAuth from './GoogleAuth.vue';
import * as yup from 'yup';
import { Form } from 'vee-validate';
import { useAuthStore } from '@/stores/auth';
import { registerApi } from '@/services/auth';
import { ref } from 'vue';
import { log, handleServerError } from "@/utils/index"
import * as Sentry from '@sentry/vue';

const loading = ref<boolean>(false);
const errorMessage = ref();
const googleAuthRef = ref();

const schema = yup.object({
    name: yup.string().required("This field is required"),
    email: yup.string().required("This field is required").email(),
    password: yup.string().required("This field is required").min(8),
    password_confirmation: yup.string().oneOf([yup.ref('password')], 'Passwords must match').required("This field is required")
});

const clearTabMessages = () => {
    errorMessage.value = null;
}

const authStore = useAuthStore();
const submit = async (_event: any, actions: any) => {
    loading.value = true;
    errorMessage.value = null;
    googleAuthRef.value.clearMessages()

    try {
        const res = await registerApi({ name: _event.name, email: _event.email, password: _event.password, password_confirmation: _event.password_confirmation });
        if (res.status == 201) {
            await authStore.updateUserData(res.json);
        } else {
            if (res?.json?.error) {
                handleServerError(res?.json?.error)
            }
            throw new Error("Failed to register")
        }
    }
    catch (err) {
        log(err);
        Sentry.captureException(err);
        if (typeof err === "string")
            errorMessage.value = "Failed to register: " + err;
        else if (err instanceof Error)
            errorMessage.value = "Failed to register: " + err.message;

    } finally {
        loading.value = false;
    }

}

</script>

<style>
.divider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.divider::before,
.divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
    margin-right: .25em;
}

.divider:not(:empty)::after {
    margin-left: .25em;
}
</style>