<script setup lang="ts">
import HomeView from './views/HomeView.vue';
import { captchaMessagesListener } from './services/handleCaptchaRequest';


window.addEventListener('message', captchaMessagesListener)
</script>

<template>
    <HomeView />
</template>

