import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import BadgeDirective from 'primevue/badgedirective'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import Dialog from 'primevue/dialog'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import './assets/main.scss'
import 'primevue/resources/themes/lara-light-cyan/theme.css'

const pinia = createPinia()
const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://9bef626d16bc15445f17fad65a50c596@o4507544458493952.ingest.us.sentry.io/4507544461639680',
  environment: import.meta.env.VITE_APP_ENV,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/poshpoptools\.com/,
    /^https:\/\/poshpoptools-dev\.com/,
    /^\//
  ]
})

app.use(pinia)
app.use(PrimeVue)
app.use(router)
app.use(ToastService)
app.use(ConfirmationService)
app.directive('badge', BadgeDirective)
app.directive('tooltip', Tooltip)
app.component('Dialog', Dialog)
app.mount('#poshpop-extension-app')
