<template>
    <Message class="bg-white" severity="error" v-if="errorMessage" icon="null" :closable="false">
        {{ errorMessage }}
    </Message>
    <Message class="bg-white" severity="success" v-if="successMessage" icon="null" :closable="false">
        {{ successMessage }}
    </Message>
    <Carousel class="w-full p-0 my-2" contentClass="flex align-items-center" :value="plans" :numVisible="1" :numScroll="0"
        :showNavigators=false>
        <template #item="slotProps">
            <div class="border-1 surface-border border-round">
                <Card class="">
                    <template #title>
                        <h6 class="bg-[#0070b7] text-white mb-4 text-center p-2 rounded-sm">{{ slotProps.data.title }}</h6>
                    </template>
                    <template #content>
                        <div>
                            <p class="flex justify-start mx-1">{{ slotProps.data.desc }}</p>
                            <Divider />
                            <p class="text-center"> <span class="font-bold">${{ slotProps.data.price }}</span> / Month</p>
                            <div class="text-center" v-for="(item, index) in slotProps.data.features" :key="index">
                                <Divider />
                                <p>{{ item }}</p>
                            </div>
                        </div>
                        <div class="text-center">
                            <Button v-if="!authStore.loggedIn"
                                class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] mt-4 text-center"
                                @click="tabView.changeActiveTab(3)">
                                Login to subscribe
                            </Button>
                            <div v-else>
                                <Button
                                    v-if="slotProps.data.id == authStore.user?.subscription?.planId && !authStore.user?.subscription?.onGracePeriod"
                                    label="Cancel Plan" :loading="cancelLoading"
                                    class="border border-red-500 font-bold bg-red-500 text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-red-500 mt-4 text-center"
                                    @click="cancel()"></Button>
                                <Button v-else label="Subscribe" :loading="enrollLoading"
                                    class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] mt-4 text-center"
                                    @click="enroll(slotProps.data.id)">

                                </Button>
                            </div>
                        </div>

                    </template>
                </Card>

            </div>
        </template>
    </Carousel>
</template>


<script setup lang="ts">
import Button from "primevue/button";
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Message from 'primevue/message';
import Divider from 'primevue/divider';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useActiveTab } from '@/stores/activeTab';
import { cancelApi } from "@/services/subscription";
import { log } from '@/utils/index';
import { serverDirectUrl, stripePricePlanIds } from '@/globals/env';
import { syncSession } from '@/services/auth';
import { formatDate } from "@/utils/index";
import * as Sentry from '@sentry/vue';

const authStore = useAuthStore();
const tabView = useActiveTab();
const errorMessage = ref();
const successMessage = ref();

const enrollLoading = ref(false);
const cancelLoading = ref(false);

const plans = ref([
    {
        id: stripePricePlanIds[0],
        title: 'Premium - $14.99/Month',
        desc: 'Unlock our entire suite of tools designed to maximize your sales and simplify your workflow:',
        price: 14.99,
        features: [
            "Auto Send Offers to Likers",
            "Repeat Daily Option",
            "Exclude Items by URL",
            "Unlimited Captcha Solving",
            "One Time, Continuous, and Scheduled Sharing",
            "Keep Order, Reverse, or Randomize Share Option",
            "Follow Just Joined Closets",
            "Follow By Username",
            "Follow Your Followers",
            "Detect Old Items via Closet Scanner",
            "Closet Calculator for Research",
            "Share to Followers",
            "Auto Party Share",
            "Send Offers to Likers in Bulk",
            "Adjust Prices in Bulk",
            "And more!",
        ]
    },
])

//called by parent ref on tab change
const refresh = () => {
    errorMessage.value = null
    successMessage.value = null
    enrollLoading.value = false
    cancelLoading.value = false
}

const enroll = async (id: string) => {
    try {
        //make sure session is valid before redirecting (to prevent redirecting with expired or invalid token/session)
        errorMessage.value = null
        enrollLoading.value = true
        await syncSession()
        if (!authStore.loggedIn) {
            throw new Error("Session timedout. You must be logged in.")
        }

        const subscriptionURl = serverDirectUrl + 'checkout/' + authStore.user?.token
        window?.open(subscriptionURl, '_blank')?.focus();

    } catch (error) {
        errorMessage.value = "Session timedout. You must be logged in."
        Sentry.captureException(error);
    } finally {
        enrollLoading.value = false
    }
}

const cancel = async () => {

    try {
        cancelLoading.value = true;
        errorMessage.value = null;
        const res = await cancelApi();
        if (res.status != 200)
            throw new Error(JSON.stringify(res.json.error))
        await syncSession()

        if (res.json?.data?.success) {
            successMessage.value = res.json?.data?.success
            if (res.json?.data?.currentPlan?.ends_at) {
                const endDate = new Date(res.json?.data?.currentPlan?.ends_at)

                successMessage.value += ` Your subscription ends on ` + formatDate(endDate) + "."
            }


        }

    }
    catch (err) {
        log(err);
        Sentry.captureException(err);
        if (typeof err === "string")
            errorMessage.value = err;
        else if (err instanceof Error)
            errorMessage.value = err.message;

    } finally {
        cancelLoading.value = false;
    }

}

defineExpose({
    refresh
})

</script>

<style scoped lang="scss">
:deep(.p-carousel-indicators) {
    .p-carousel-indicator {
        button {
            background-color: #e2e8f0 !important;
        }

        &.p-highlight {
            button {
                background-color: #0070b7 !important;
            }
        }
    }
}

:deep(.p-divider-horizontal) {
    padding: 0 !important;
    margin: 5px 0 !important;

    &::before {
        border-width: 1px !important;
        border-radius: 0 !important;
    }
}

:deep(.p-carousel-items-container) {
    transform: unset !important;

    .p-carousel-item {
        display: none;
        visibility: hidden;
        flex: unset !important;

        &.p-carousel-item-active {
            display: flex;
            visibility: visible;
        }
    }
}
</style>