import { serverUrl } from '@/globals/env'
import { useAuthStore } from '@/stores/auth'
import * as Sentry from '@sentry/vue'

//Note: enrollApi is currently not being used since subscription is now through the website
export const enrollApi = async (data: { plan: string; coupon: string }) => {
  const authStore = useAuthStore()
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: 'Bearer ' + authStore.user.token
      },
      body: JSON.stringify({ plan: data.plan, coupon: data.coupon })
    }

    let response = await fetch(serverUrl + 'enroll-subscription', options)

    //refresh token if it has expired
    if (response.status === 419) {
      await authStore.refreshToken()
      options.headers['Authorization'] = 'Bearer ' + authStore.user.token
      response = await fetch(serverUrl + 'enroll-subscription', options)
    } else if (response.status === 401) {
      //logout user and return
      await authStore.destroyUserData()
      return { status: response.status, json: { message: 'invalid session' } }
    }

    const status = response.status
    const json = await response.json()
    return { status, json }
  } catch (err) {
    Sentry.captureException(err)
    throw new Error(`Failed to subscribe: ${err}`)
  }
}

export const cancelApi = async () => {
  const authStore = useAuthStore()
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
        Authorization: 'Bearer ' + authStore.user.token
      }
    }

    let response = await fetch(serverUrl + 'cancel-subscription', options)

    //refresh token if it has expired
    if (response.status === 419) {
      await authStore.refreshToken()
      options.headers['Authorization'] = 'Bearer ' + authStore.user.token
      response = await fetch(serverUrl + 'cancel-subscription', options)
    } else if (response.status === 401) {
      //logout user and return
      await authStore.destroyUserData()
      return { status: response.status, json: { message: 'invalid session' } }
    }

    const status = response.status
    const json = await response.json()
    return { status, json }
  } catch (err) {
    Sentry.captureException(err)
    throw new Error(`Failed to cancel subscription: ${err}`)
  }
}
