<template>
    <div>
        <TabView v-if="!authStore.loggedIn || !authStore.user.user" class="h-full bg-white" :scrollable="true" :lazy="true">
            <TabPanel>
                <template #header>
                    <h6 class="text-sm">Sign in</h6>
                </template>
                <LoginForm />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <h6 class="text-sm">Sign up</h6>
                </template>
                <RegisterForm @register="onRegister" />
            </TabPanel>

        </TabView>
        <div v-else>
            <Card v-if="!authStore?.user?.user?.email_verified_at">
                <template #content>
                    <VerifyAccount></VerifyAccount>
                </template>
            </Card>
            <Card class="mt-2">
                <template #title>
                    <h6 class="text-center">
                        Hi, {{ authStore.user.user.name }}!
                    </h6>
                </template>
                <template #content>
                    <div class="text-red-600 font-semibold py-2">{{ errorMessage }}</div>
                    <div class="px-4 mt-2">
                        <p>Email: <span class="font-semibold">{{ authStore.user.user?.email }}</span></p>
                        <p
                            v-if="!authStore.user.subscription && Date.parse(authStore.user.user?.trial_ends_at) > Date.now()">
                            Trial ends on: <span class="font-semibold">{{ formatDate(new
                                Date(authStore.user.user?.trial_ends_at)) }}</span>
                        </p>
                        <p v-if="authStore.user.subscription && !authStore.user.subscription.ended_at">
                            Plan: <span class="font-semibold">{{ authStore.user.subscription.planName?.replace('plan', '')
                                ??
                                "Premium" }}</span>
                        </p>
                        <p v-else>
                            Plan: <span class="font-semibold">Free</span>
                        </p>
                        <p
                            v-if="authStore.user.user?.subscriptions && authStore.user.user?.subscriptions.length > 0 && authStore.user.subscription?.onGracePeriod && authStore.user.subscription?.currentPlan">
                            Plan ends on: <span class="font-semibold">{{ formatDate(new
                                Date(authStore.user.subscription?.currentPlan.ends_at))
                            }}</span>
                        </p>

                    </div>

                    <div class="w-full flex justify-end">
                        <Button label="Logout" icon="pi pi-sign-out" :loading="loading" type="submit"
                            class="w-fit border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] mt-4"
                            @click="logout()"></Button>
                    </div>
                </template>
            </Card>
            <!-- <Card class="mt-4" v-if="authStore.user.user?.card_brand">
                <template #title>Payment</template>
                <template #content>
                    <div class="mt-2">
                        <p class="text-xl font-bold mb-2">{{ authStore.user.user?.card_brand }}</p>
                        <p class="text-lg font-semibold">**** **** **** {{ authStore.user.user?.card_last_four }}</p>
                    </div>
                </template>
            </Card> -->
            <Card class="mt-2">
                <template #title>Recent Invoices</template>
                <template #content>
                    <DataTable :value="authStore.user.invoices" tableStyle="" class="mt-2">
                        <template #empty> No recent invoices </template>
                        <Column field="date" header="Date"></Column>
                        <Column field="total" header="Total"></Column>
                        <Column field="pdf" header="Invoice">
                            <template #body="{ data }">
                                <a @click="sendMessage({ message: 'openLink', link: `${data.pdf}}` })" download
                                    class="text-[#0070b7] hover:font-semibold cursor-pointer">
                                    Invoice
                                </a>
                            </template>
                        </Column>
                    </DataTable>
                    <p class="text-xs text-center my-4">You can send us your feedback <a
                            class="text-[#0070b7] hover:font-semibold cursor-pointer"
                            href="https://poshpopassistant.com/contact-us" target="_blank">here</a></p>
                </template>
            </Card>
            <Card class="mt-4" v-if="authStore.user.user?.card_last_four">
                <template #title>Payment Options</template>
                <template #content>
                    <div class="mt-2">
                        <Button label="Update Payment" :loading="updatePaymentLoading"
                            class="w-full border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] mt-4 text-center"
                            @click="updatePayment()"></Button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import LoginForm from './LoginForm.vue';
import RegisterForm from './RegisterForm.vue';
import VerifyAccount from './VerifyAccount.vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import * as Sentry from '@sentry/vue';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { createReferralCodeAPI, logoutApi, syncSession } from '@/services/auth';
import { serverDirectUrl } from '@/globals/env';
import { sendMessage, log, formatDate } from "@/utils/index"

const loading = ref(false);
const referralLoading = ref(false);
const updatePaymentLoading = ref(false);
const authStore = useAuthStore();
const errorMessage = ref();
const referralCodeErrorMessage = ref();
const isSignupSuccessful = ref<boolean>(false);

//called by parent ref on tab change
const refresh = () => {
    errorMessage.value = null
    referralCodeErrorMessage.value = null
    loading.value = false
    referralLoading.value = false
    updatePaymentLoading.value = false
}

const updatePayment = async () => {

    try {
        //make sure session is valid before redirecting (to prevent redirecting with expired or invalid token/session)
        errorMessage.value = null
        updatePaymentLoading.value = true
        await syncSession()
        if (!authStore.loggedIn) {
            throw new Error("Session timedout. You must be logged in.")
        }

        const subscriptionURl = serverDirectUrl + 'update-payment-method/' + authStore.user?.token
        window?.open(subscriptionURl, '_blank')?.focus();

    } catch (error) {
        log(error)
        Sentry.captureException(error);
    } finally {
        updatePaymentLoading.value = false
    }
}

const logout = async () => {
    loading.value = true;
    errorMessage.value = null;

    try {
        const res = await logoutApi();
        if (res.status == 200) {
            await authStore.destroyUserData();
        } else {
            throw new Error(JSON.stringify(res?.json?.error))
        }

        refresh()
    }
    catch (err) {
        log(err);
        Sentry.captureException(err);
        if (typeof err === "string")
            errorMessage.value = err;
        else if (err instanceof Error)
            errorMessage.value = err.message;

    } finally {
        loading.value = false;
    }
}

const createReferralCode = async () => {
    referralLoading.value = true;
    referralCodeErrorMessage.value = null;

    try {
        const res = await createReferralCodeAPI();
        if (res.status == 200) {
            authStore.updateReferralCode(res.json.referrerCode);
        } else
            throw new Error(JSON.stringify(res?.json?.error))

    }
    catch (err) {
        log(err);
        Sentry.captureException(err);
        if (typeof err === "string")
            referralCodeErrorMessage.value = err;
        else if (err instanceof Error)
            referralCodeErrorMessage.value = err.message;

    } finally {
        referralLoading.value = false;
    }
}

const onRegister = () => {
    isSignupSuccessful.value = true;
    errorMessage.value = null;
}

defineExpose({
    refresh
})
</script>