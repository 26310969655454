<template>
    <div id="poshpop-extension-app-container"
        class="h-screen w-[450px] box-border p-0 shadow-lg z-[9999999] bg-[#293f51] overflow-y-auto overflow-x-hidden">
        <Button
            class="w-10 h-10 d-flex justify-center items-center rounded-full bg-[#e6e7ec] text-xl text-white absolute top-2 -left-12 hover:cursor-pointer">
            x
        </Button>
        <div class="card h-full">
            <TabView class="h-fit" v-model:activeIndex="tabView.activeTab" @tab-change="onTabChange">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-home" style="font-size: 1rem"></i>
                    </template>
                    <RouterView v-slot="{ Component }">
                        <MessageHandler ref="messagesComponentRef" @changeActiveTab=changeActiveTab />
                        <component :is="Component" />
                    </RouterView>
                </TabPanel>
                <TabPanel>
                    <template #header>

                        <i v-if="notificationsCount > 0" v-badge="notificationsCount" class="pi pi-bell"
                            style="font-size: 1rem" @click="clearNotifications"></i>
                        <i v-else class="pi pi-bell" style="font-size: 1rem"></i>
                    </template>
                    <NotificationsTab :notificationsCount="notificationsCount" :notifications="notifications" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-dollar" style="font-size: 1rem"></i>
                    </template>
                    <SubscriptionTab ref="subscriptionComponentRef" />
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-user" style="font-size: 1rem"></i>
                    </template>
                    <AccountTab ref="accountComponentRef" />
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Badge from 'primevue/badge';
import MessageHandler from './MessageHandler.vue';
import NotificationsTab from './NotificationsTab.vue';
import SubscriptionTab from './SubscriptionTab.vue';
import AccountTab from './AccountTab.vue';
import Notification from "@/classes/Notification"
import { RouterView } from 'vue-router';
import { useActiveTab } from '@/stores/activeTab';
import { onMounted, onUnmounted, ref } from 'vue';
import { log } from "@/utils/index";


const tabView = useActiveTab();
const messagesComponentRef = ref()
const subscriptionComponentRef = ref()
const accountComponentRef = ref()

const notificationsCount = ref<number>(0)
const notifications = ref<Notification[]>([])

const onTabChange = () => {
    //reset tabs
    subscriptionComponentRef.value.refresh();
    accountComponentRef.value.refresh();
}

onMounted(() => {
    //reset listeners to prevent duplicate listeners
    window.addEventListener("message", messagesHandler);
})

onUnmounted(() => {
    //reset listeners to prevent duplicate listeners
    window.removeEventListener('message', messagesHandler);
})

//This will handle messages sent from the extension to the web server
const messagesHandler = (event: any) => {
    if (event.data.message == "new-notifications") {

        log(event.data)
        notificationsCount.value = event.data.newCount
        notifications.value = event.data.notifications
    }
}

const clearNotifications = () => {
    notificationsCount.value = 0
}

const displayWarnMessage = (data: any) => {
    messagesComponentRef.value.displayWarnMessage(data)
}

const changeActiveTab = (tab: number) => {
    tabView.changeActiveTab(tab)
}

defineExpose({
    displayWarnMessage
})

</script>


<style scoped lang="scss">
:deep(.p-tabview) {
    .p-tabview-nav {
        border: 2px solid #293f51;
        margin-bottom: 0;


        .p-tabview-header {
            color: #222;
            font-size: 18px;
            padding: 0;
            flex: auto;

            &.p-highlight {
                .p-tabview-nav-link {
                    color: #222;
                    background-color: #e6e7ec;
                    font-weight: bold;
                }
            }

            .p-tabview-nav-link {
                justify-content: center;
                border-radius: 0;
            }
        }
    }

    .p-tabview-panels {
        padding: 10px 10px;
        background-color: #293f51;
        min-height: -webkit-fill-available;
        border-radius: 0;
    }
}

:deep(.p-card-body) {
    padding: 15px;

    .p-card-caption {
        .p-card-title {
            margin-bottom: 0;
        }
    }

    .p-card-content {
        padding: 0;
    }
}

:deep(.p-inputtext) {
    height: 35px;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

:deep(.p-calendar) {
    padding-top: 0;
    padding-bottom: 0;
}

:deep(.p-button) {
    padding: 5px 20px;
}
</style>

<style>
/*Styling scroll bar */

#poshpop-extension-app-container::-webkit-scrollbar {
    /* width of the entire scrollbar */
    width: 12px;
}

#poshpop-extension-app-container::-webkit-scrollbar-thumb {
    /* color of the scroll thumb */
    background-color: rgba(0, 112, 183, 0.4);
    /* roundness of the scroll thumb */
    border-radius: 20px;
    /* creates padding around scroll thumb */
    border: 1px solid white;
}

#poshpop-extension-app-container::-webkit-scrollbar-track {

    /* creates padding around scroll thumb */
    border: 1px solid white;
    background-color: white;
}
</style>