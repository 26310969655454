class ServerRequestError extends Error {
	static TOKEN_EXPIRED = "Token has expired"
	static RESET_SESSION = "Session has expired"
	code?
	status

	constructor(message: string, status: string | number, code?: number) {
		super(message)
		this.status = status
		this.code = code
		this.name = "ServerRequestError"
	}
}

export default ServerRequestError
