<template>
    <TabNavigation :is-active="taskStatus.scanCloset.status" />
    <div class="p-0 h-full w-full z-[9999999] overflow-y-auto overflow-x-hidden" v-show="!hideUI">
        <Card class="p-0">
            <template #title>
                <div class="flex items-center justify-between">
                    <h6 class="text-base">Scan Closet <i title="This feature runs instantly and does not get scheduled"
                            class="pi pi-bolt text-blue-500 border-blue-500 border-2 p-[1px] rounded-full ml-1"></i></h6>

                </div>
            </template>
            <template #content>
                <div v-if="validations.has('scanCloset')">
                    <Message severity="error" v-for="(errorMessage, index) of validations.get('scanCloset')"
                        :keys="errorMessage" icon="null" :closable="false">
                        {{ errorMessage }}
                    </Message>
                </div>
                <div class="flex items-center gap-3 mt-4">
                    <div class="flex items-center gap-x-3">
                        <span>Check for items older than</span>
                        <InputNumber :min="1" placeholder="0"
                            :input-class="'w-[100px] p-3 border border-[#0070b7] rounded-lg '" v-model="scanOlderThan"
                            inputId="scanOlderThan" :useGrouping="true" :disabled="taskStatus.scanCloset.status" />
                        <span>days</span>
                    </div>
                </div>
                <div v-if="isLoading" class="flex text-center py-6">
                    <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
                        aria-label="Custom ProgressSpinner" />
                </div>
                <div v-if="noItemsFound" class="flex text-center">
                    <Message class="w-full" severity="success" icon="null" :closable="false">
                        All closet items are up to date
                    </Message>
                </div>
                <div class="flex justify-end">
                    <div class="flex justify-end mt-5 gap-x-4">
                        <Button v-if="taskStatus.scanCloset.status" @click="requireConfirmation('top')"
                            class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                            Stop Current Task
                        </Button>
                        <Button v-else
                            class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                            @click="validateScanClosetRequest()">
                            Scan
                            <div v-if="!authStore?.hasActivePlan()"
                                class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </Button>
                    </div>
                </div>
                <div v-if="closetOldItems.length" class="mt-3">

                    <h6>Scanned old Items:</h6>
                    <h6 class="mt-2"><strong>Duration:</strong> {{ scanStartDate }} - {{ scanEndDate }}</h6>
                    <div v-for="(item, index) in closetOldItems" :key="index"
                        class="flex items-start justify-between border border-blue-500 rounded-lg mt-3 p-4 hover:cursor-pointer"
                        @click="sendMessage({ message: 'openLink', link: `https://poshmark.com/listing/${item.title}-${item.id}` })">
                        <div class="flex gap-x-4">
                            <img style="width: 50px; height: 50px;"
                                :src="'https://di2ponv0v5otw.cloudfront.net/' + item.cover_shot.path_small"
                                alt="product Image">
                            <div>
                                <h6>{{ item.title }}</h6>
                                <p>Price: $<span class="text-blue-500">{{ item.price.toLocaleString(undefined, {
                                    minimumFractionDigits: 2, maximumFractionDigits: 2
                                }) }}</span></p>
                                <p>Updated at: {{ new Date(item.updated_at).toLocaleString() }}</p>
                            </div>
                            <div class="flex justify-end">
                                <i class="pi pi-external-link text-blue-500"></i>
                            </div>
                        </div>
                        <!-- <Button
                        class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7] min-w-[85px] justify-center" @click="sendMessage({ message: 'shareSingleProduct', productID: item.id })">
                            share
                        </Button> -->
                    </div>
                </div>
            </template>
        </Card>

        <ConfirmDialog group="positioned"></ConfirmDialog>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useTaskStatusStore } from '@/stores/taskStatus';
import { useConfirm } from 'primevue/useconfirm';
import { log } from "@/utils/index";
import { format } from "date-fns"
import { useActiveTab } from '@/stores/activeTab';
import { useAuthStore } from "../stores/auth";
import TabNavigation from "./TabNavigation.vue"
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import Card from 'primevue/card';
import TaskType from "../enums/TaskType";
import ConfirmDialog from 'primevue/confirmdialog';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import { useRouter } from 'vue-router';
import * as Sentry from '@sentry/vue';
import LocalStorageWrapper from '@/classes/LocalStorageWrapper';

const hideUI = ref(true)
const localStorageWrapper = LocalStorageWrapper.getInstance()
const authStore = useAuthStore();
const tabView = useActiveTab();
const confirm = useConfirm();
const taskStatus = useTaskStatusStore();
const route = useRouter();
const isScanClosetEnabled = ref<boolean>(taskStatus.scanCloset.status)
const delayRange = ref<number[]>([1, 1]);
const validations = ref(new Map())
const scanOlderThan = ref<number>();
const closetOldItems = ref<any>([])
const isLoading = ref<boolean>(false)
const scanStartDate = ref<string>()
const scanEndDate = ref<string>()
const noItemsFound = ref<boolean>(false)

onMounted(async () => {
    //load last used settings if found
    await loadScanClosetSettings();
    hideUI.value = false
})

const loadScanClosetSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('scanClosetSettings')
        const scanClosetSettings = JSON.parse(storedData as string)

        if (!scanClosetSettings) return

        isLoading.value = !!scanClosetSettings.isLoading && taskStatus.scanCloset.status
        closetOldItems.value = scanClosetSettings.closetOldItems ?? []
        scanStartDate.value = scanClosetSettings.scanStartDate
        scanEndDate.value = scanClosetSettings.scanEndDate
        scanOlderThan.value = scanClosetSettings.scanOlderThan ?? 0
        delayRange.value = scanClosetSettings.delayRange ?? [1, 1]
        noItemsFound.value = !!scanClosetSettings.noItemsFound



        //store latest settings in local storage
        if (!isLoading.value) {
            //clear previous scan data. In case it was stored when the tab was not active
            scanClosetSettings.closetOldItems = []
            scanClosetSettings.noItemsFound = false
            localStorageWrapper.set('scanClosetSettings', JSON.stringify(scanClosetSettings))
        }
        else {
            //reset listeners to reflect latest, mounted ClosetScannerTab vue
            window.removeEventListener('message', scanClosetMessagesHandler);
            window.addEventListener('message', scanClosetMessagesHandler);
        }
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }
}


const sendMessage = (msg: any) => {
    window.parent.postMessage(msg, "*");
}

const validateScanClosetRequest = () => {
    if (!authStore?.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.scanCloset.status) return

    log("Validating scan closet request")

    let messages = []

    if (!delayRange.value[0] || delayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (!delayRange.value[1] || delayRange.value[1] < delayRange.value[0])
        messages.push("Invalid delay range.");

    if (!scanOlderThan.value || scanOlderThan.value <= 0)
        messages.push("Days must be greater than 0");


    validations.value.set("scanCloset", messages);

    if (messages.length) return

    //update and send to extension to start task
    const taskData = { message: 'closetScanner', scanOlderThan: scanOlderThan.value, delay: { min: delayRange.value[0], max: delayRange.value[1] } }

    //start message listener
    window.addEventListener("message", scanClosetMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeScanClosetStatus(true);
    isLoading.value = true
    closetOldItems.value = []

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: delayRange.value,
        isLoading: isLoading.value,
        closetOldItems: [],
        scanStartDate: "",
        scanEndDate: "",
    }

    //store latest settings in local storage
    localStorageWrapper.set('scanClosetSettings', JSON.stringify(config))
}

const requireConfirmation = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        rejectClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        acceptClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Keep Task running',
        acceptLabel: 'Stop Task',
        header: 'Are you sure you want to stop this task?',
        message: 'Please confirm to proceed.',
        accept: () => {
            sendMessage({ message: 'stopScanClosetTask' });
        }
    });
};

const upgradeSubscriptionAlert = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        acceptClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        rejectClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Cancel',
        acceptLabel: 'Subscribe',
        header: 'Upgrade Subscription',
        message: 'Upgrade subscription to enable this feature.',
        accept: () => {
            tabView.changeActiveTab(2)
        }
    });
};

//This will handle messages sent from the extension to the web server
const scanClosetMessagesHandler = async (event: any) => {
    if (event.data.message == "showScannedItems") {
        isLoading.value = false;
        closetOldItems.value = event.data.result;
        scanStartDate.value = format(event.data.scanStartDate, "MM/dd/yyyy")
        scanEndDate.value = format(event.data.scanEndDate, "MM/dd/yyyy")
        noItemsFound.value = !!!closetOldItems.value.length
        const storedData = await localStorageWrapper.get('scanClosetSettings')
        const scanClosetSettings = JSON.parse(storedData as string)

        //store scanned list only if the tab is not active
        if (route.currentRoute.value.name !== "closet-scanner") {
            scanClosetSettings.closetOldItems = closetOldItems.value
            scanClosetSettings.noItemsFound = noItemsFound.value
        }

        scanClosetSettings.scanStartDate = scanStartDate.value
        scanClosetSettings.scanEndDate = scanEndDate.value
        scanClosetSettings.isLoading = false

        //store latest settings in local storage
        localStorageWrapper.set('scanClosetSettings', JSON.stringify(scanClosetSettings))

    }
    else if (event.data.message == "scanClosetTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeScanClosetStatus(false)
            isLoading.value = false;
            //stop message listener until the task is started again
            window.removeEventListener("message", scanClosetMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartScanClosetTask") {

        //validations.value.set("scanCloset", [event.data.error]);
        taskStatus.changeScanClosetStatus(false);
        isLoading.value = false;
        //stop message listener until the task is started again
        window.removeEventListener("message", scanClosetMessagesHandler);
    }
}

</script>