<template>
    <TabNavigation
        :is-active="taskStatus.follow.status || taskStatus.followJustJoined.status || taskStatus.followByUsername.status"
        :is-scheduled="taskStatus.follow.daily || taskStatus.followJustJoined.daily || taskStatus.followByUsername.daily" />
    <LoadingScreen :isLoading="isLoading" />
    <div class="p-0 h-full w-full z-[9999999] overflow-y-auto overflow-x-hidden" v-show="!hideUI">
        <Card class="mt-2 p-0">
            <template #title>
                <div class="flex items-center justify-between">
                    <h6 class="text-base">Followers</h6>
                    <div class="flex items-center">
                        <InputSwitch input-id="isFollowEnabled" v-model="isFollowEnabled" onLabel="ON" offLabel="OFF"
                            class="w-2rem me-2" @change="onFollowChange" :disabled="taskStatus.follow.status" />
                    </div>
                </div>
            </template>
            <template #content v-if="isFollowEnabled">
                <div v-if="validations.has('follow')">
                    <Message severity="error" v-for="(errorMessage, index) of validations.get('follow')"
                        :keys="errorMessage" icon="null" :closable="false">
                        {{ errorMessage }}
                    </Message>
                </div>
                <div class="py-3">
                    <h6 class="text-base">Time Delay</h6>
                    <div class="flex justify-between w-full ">
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="followDelayRange[0]" :min="0" :max="followDelayRange[1] - 1"
                                inputClass="!w-[30px] text-center" :disabled="taskStatus.follow.status" />
                            <span>Sec</span>
                        </div>
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="followDelayRange[1]" :min="followDelayRange[0] + 1" :max="60"
                                inputClass="!w-[30px] text-center" :disabled="taskStatus.follow.status" />
                            <span>Sec</span>
                        </div>
                    </div>
                    <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles" v-model="followDelayRange" range
                        :step="1" ariaLabel="sec" :min="1" :max="50" class="mx-2" :disabled="taskStatus.follow.status" />
                </div>
                <div class="flex items-center gap-3 mt-2">
                    <label for="limit">
                        Follow
                    </label>
                    <span>
                        <InputNumber :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg '" placeholder="0"
                            v-model="followLimit" inputId="limit" :useGrouping="true" :max=1000
                            :disabled="taskStatus.follow.status" /> (Max 1000)
                    </span>
                </div>

                <div class="flex items-center mt-2">
                    <InputSwitch input-id="followRepeatDaily" v-model="followRepeatDaily" onLabel="ON" offLabel="OFF"
                        class="w-2rem me-2" :disabled="taskStatus.follow.status" />
                    <label :for="'followRepeatDaily'" class="hover:cursor-pointer">
                        Repeat Daily
                    </label>
                </div>

                <div class="flex justify-end">
                    <div class="flex justify-end mt-5 gap-x-4">
                        <Button v-if="taskStatus.follow.status" @click="requireConfirmation('top', 'stopFollowTask')"
                            class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                            Stop Current Task
                        </Button>
                        <Button v-else
                            class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                            @click="validateFollowRequest()">
                            Start
                            <div v-if="!authStore?.hasActivePlan()"
                                class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </Button>
                    </div>
                </div>
            </template>
        </Card>
        <Card class="mt-4 p-0">
            <template #title>
                <div class="flex items-center justify-between">
                    <h6 class="text-base">Follow Just Joined Closets</h6>
                    <div class="flex items-center">
                        <InputSwitch v-model="isFollowJustJoined" onLabel="ON" offLabel="OFF" class="w-2rem me-2"
                            @change="onFollowJustJoinedChange" :disabled="taskStatus.followJustJoined.status" />
                    </div>
                </div>
            </template>
            <template #content v-if="isFollowJustJoined">
                <div v-if="validations.has('followJustJoined')">
                    <Message severity="error" v-for="(errorMessage, index) of validations.get('followJustJoined')"
                        :keys="errorMessage" icon="null" :closable="false">
                        {{ errorMessage }}
                    </Message>
                </div>
                <div class="py-3">
                    <h6 class="text-base">Time Delay</h6>
                    <div class="flex justify-between w-full ">
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="followJustJoinedDelayRange[0]" :min="0"
                                :max="followJustJoinedDelayRange[1] - 1" inputClass="!w-[30px] text-center"
                                :disabled="taskStatus.followJustJoined.status" />
                            <span>Sec</span>
                        </div>
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="followJustJoinedDelayRange[1]" :min="followJustJoinedDelayRange[0] + 1"
                                :max="60" inputClass="!w-[30px] text-center"
                                :disabled="taskStatus.followJustJoined.status" />
                            <span>Sec</span>
                        </div>
                    </div>
                    <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles"
                        v-model="followJustJoinedDelayRange" range :step="1" ariaLabel="sec" :min="1" :max="50" class="mx-2"
                        :disabled="taskStatus.followJustJoined.status" />
                </div>

                <div class="flex items-center gap-3 mt-2">

                    <label for="followJustJoinedLimit">
                        Follow
                    </label>
                    <span>
                        <InputNumber :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg '" placeholder="0"
                            v-model="followJustJoinedLimit" inputId="followJustJoinedLimit" :useGrouping="true" :max=1000
                            :disabled="taskStatus.followJustJoined.status" /> (Max 1000)
                    </span>
                </div>
                <div class="flex items-center mt-2">
                    <InputSwitch input-id="followJustJoinedRepeatDaily" v-model="followJustJoinedRepeatDaily" onLabel="ON"
                        offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.followJustJoined.status" />
                    <label :for="'followJustJoinedRepeatDaily'" class="hover:cursor-pointer">
                        Repeat Daily
                    </label>
                </div>
                <div class="flex justify-end">
                    <div class="flex justify-end mt-5 gap-x-4">
                        <Button v-if="taskStatus.followJustJoined.status"
                            @click="requireConfirmation('top', 'stopFollowJustJoinedTask')"
                            class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                            Stop Current Task
                        </Button>
                        <Button v-else
                            class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                            @click="validateFollowJustJoinedRequest()">
                            Start
                            <div v-if="!authStore?.hasActivePlan()"
                                class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </Button>
                    </div>
                </div>
            </template>
        </Card>
        <Card class="mt-4 p-0">
            <template #title>
                <div class="flex items-center justify-between">
                    <h6 class="text-base">Follow By Username</h6>
                    <div class="flex items-center mt-2">
                        <InputSwitch input-id="followByUsername" v-model="isFollowByUsername" onLabel="ON" offLabel="OFF"
                            class="w-2rem me-2" @change="onFollowByUsernameChange"
                            :disabled="taskStatus.followByUsername.status" />
                    </div>
                </div>
            </template>
            <template #content v-if="isFollowByUsername">
                <div v-if="validations.has('followByUsername')">
                    <Message severity="error" v-for="(errorMessage, index) of validations.get('followByUsername')"
                        :keys="errorMessage" icon="null" :closable="false">
                        {{ errorMessage }}
                    </Message>
                </div>
                <div class="py-3">
                    <h6 class="text-base">Time Delay</h6>
                    <div class="flex justify-between w-full">
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="followByUsernameDelayRange[0]" :min=0
                                :max="followByUsernameDelayRange[1] - 1" inputClass="!w-[30px] text-center"
                                :disabled="taskStatus.followByUsername.status" />
                            <span>Sec</span>
                        </div>
                        <div class="flex items-center mb-2">
                            <InputNumber v-model="followByUsernameDelayRange[1]" :min="followByUsernameDelayRange[0] + 1"
                                :max=50 inputClass="!w-[30px] text-center" :disabled="taskStatus.followByUsername.status" />
                            <span>Sec</span>
                        </div>
                    </div>
                    <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles"
                        v-model="followByUsernameDelayRange" range :step="1" ariaLabel="sec" :min="1" :max=50 class="mx-2"
                        :disabled="taskStatus.followByUsername.status" />
                </div>
                <div class="grid grid-cols-3 pt-2">
                    <div class="col-span-1">
                        <label for="closetURL" class="mb-2">
                            Closet URL
                        </label>
                    </div>
                    <div class="col-span-2">
                        <InputText :placeholder="'https://poshmark.com/closet/closet_owner_username'"
                            class="w-full p-3 border border-[#0070b7] rounded-lg" v-model="closetURL" inputId="closetURL"
                            :useGrouping="true" :disabled="taskStatus.followByUsername.status" />
                    </div>
                </div>
                <div class="grid grid-cols-3 pt-2">
                    <div class="col-span-1">
                        <label for="followByUsernameLimit" class="mb-2">
                            Follow
                        </label>
                    </div>
                    <div class="col-span-2">
                        <InputNumber placeholder="0" :input-class="'w-full p-3 border border-[#0070b7] rounded-lg'"
                            v-model="followByUsernameLimit" inputId="followByUsernameLimit" :useGrouping="true" :max=1000
                            :disabled="taskStatus.followByUsername.status" />
                        <span class="float-end pt-1"> (Max 1000)</span>
                    </div>
                </div>
                <div class="flex items-center mt-2">
                    <InputSwitch input-id="followByUsernameRepeatDaily" v-model="followByUsernameRepeatDaily" onLabel="ON"
                        offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.followByUsername.status" />
                    <label :for="'followByUsernameRepeatDaily'" class="hover:cursor-pointer">
                        Repeat Daily
                    </label>
                </div>
                <div class="flex justify-end">
                    <div class="flex justify-end mt-5 gap-x-4">
                        <Button v-if="taskStatus.followByUsername.status"
                            @click="requireConfirmation('top', 'stopFollowByUsernameTask')"
                            class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                            Stop Current Task
                        </Button>
                        <Button v-else
                            class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                            @click="validateFollowByUsernameRequest()">
                            Start
                            <div v-if="!authStore?.hasActivePlan()"
                                class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                            </div>
                        </Button>
                    </div>
                </div>
            </template>
        </Card>
        <ConfirmDialog group="positioned"></ConfirmDialog>
    </div>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import { onMounted, ref } from 'vue';
import { useTaskStatusStore } from '@/stores/taskStatus';
import { log, isValidClosetUrl } from "@/utils/index";
import { useAuthStore } from "../stores/auth";
import { useActiveTab } from '@/stores/activeTab';
import TabNavigation from "./TabNavigation.vue"
import Tag from 'primevue/tag';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import Card from 'primevue/card';
import Slider from "primevue/slider";
import TaskType from "../enums/TaskType";
import ConfirmDialog from "primevue/confirmdialog";
import Message from 'primevue/message';
import * as Sentry from '@sentry/vue';
import LocalStorageWrapper from "@/classes/LocalStorageWrapper";
import LoadingScreen from '@/components/LoadingScreen.vue';

const isLoading = ref(false);
const hideUI = ref(true)
const localStorageWrapper = LocalStorageWrapper.getInstance()
const authStore = useAuthStore();
const tabView = useActiveTab();
const confirm = useConfirm();
const taskStatus = useTaskStatusStore();
const followRepeatDaily = ref<boolean>(false);
const followJustJoinedRepeatDaily = ref<boolean>(false);
const followByUsernameRepeatDaily = ref<boolean>(false);
const isFollowEnabled = ref<boolean>(taskStatus.follow.status)
const isFollowJustJoined = ref<boolean>(taskStatus.followJustJoined.status)
const isFollowByUsername = ref<boolean>(taskStatus.followByUsername.status)
const followLimit = ref<number>(50)
const followJustJoinedLimit = ref<number>(50)
const followByUsernameLimit = ref<number>(50)
const followDelayRange = ref<number[]>([3, 7]);
const followJustJoinedDelayRange = ref<number[]>([3, 7]);
const followByUsernameDelayRange = ref<number[]>([3, 7]);
const validations = ref(new Map())
const closetURL = ref<string>()

//PrimeVue Components Styles
const sliderStyles = {
    startHandler: { class: 'bg-[#0070b7]' },
    endHandler: { class: 'bg-[#0070b7]' },
    range: { class: 'bg-[#0070b7]' }
}

onMounted(async () => {
    //load last used settings if found
    await loadFollowSettings();
    await loadFollowJustJoinedSettings();
    await loadFollowByUsernameSettings();

    hideUI.value = false
    setTimeout(() => {
        isLoading.value = false
    }, 300)
})

const updateSliderValue = (val: number[]) => {
    val[0] = Math.floor(val[0])
    val[1] = Math.floor(val[1])
}


const onFollowChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadFollowSettings();
    }

    //reset error messages
    validations.value.set("follow", []);
}

const onFollowJustJoinedChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadFollowJustJoinedSettings();
    }

    //reset error messages
    validations.value.set("followJustJoined", []);
}

const onFollowByUsernameChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadFollowByUsernameSettings();
    }

    //reset error messages
    validations.value.set("followByUsername", []);
}

const loadFollowSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('followSettings')
        const followSettings = JSON.parse(storedData as string)

        if (!followSettings) return

        followLimit.value = followSettings.limit
        followDelayRange.value = followSettings.delayRange ?? [3, 7]
        followRepeatDaily.value = !!followSettings.repeat
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const loadFollowJustJoinedSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('followJustJoinedSettings')
        const followJustJoinedSettings = JSON.parse(storedData as string)

        if (!followJustJoinedSettings) return

        followJustJoinedDelayRange.value = followJustJoinedSettings.delayRange ?? [3, 7]
        followJustJoinedLimit.value = followJustJoinedSettings.limit
        followJustJoinedRepeatDaily.value = !!followJustJoinedSettings.repeat
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const loadFollowByUsernameSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('followByUsernameSettings')
        const followByUsernameSettings = JSON.parse(storedData as string)

        if (!followByUsernameSettings) return

        closetURL.value = followByUsernameSettings.closetURL
        followByUsernameLimit.value = followByUsernameSettings.limit
        followByUsernameDelayRange.value = followByUsernameSettings.delayRange ?? [3, 7]
        followByUsernameRepeatDaily.value = !!followByUsernameSettings.repeat
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const sendMessage = (msg: { message: string, type?: string, limit?: number, delay?: { min: number, max: number }, reverse?: string, closetURL?: string, scanClosetURL?: string, sendOfferAfter?: number, percentDiscount?: number, shippingOffer?: string, excludeStatus?: any, exemptInventoryList?: string[], listedLately?: number, listingsOlderThan?: number, priceRange?: number[], increase?: boolean, scanOlderThan?: number, productID?: string, link?: string, sharingType?: string, taskType?: TaskType, time?: string, repeat?: boolean }) => {
    window.parent.postMessage(msg, "*");
}

const validateFollowRequest = () => {

    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.follow.status || !isFollowEnabled.value) return

    log("Validating Follow request")

    let messages = []

    if (!followDelayRange.value[0] || followDelayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (!followDelayRange.value[1] || followDelayRange.value[1] < followDelayRange.value[0])
        messages.push("Invalid delay range.");

    if (!followLimit.value || followLimit.value <= 0)
        messages.push("Follow limit must be greater than 0");
    if (followLimit.value > 1000)
        messages.push("A maximum of 1000 limit is required.");


    validations.value.set("follow", messages);

    if (messages.length) return

    //update and send to extension to start task
    const taskData = { message: 'loadFollowers', type: 'follow', limit: followLimit.value, delay: { min: followDelayRange.value[0], max: followDelayRange.value[1] }, repeat: followRepeatDaily.value }

    //start message listener
    window.addEventListener("message", followMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeFollowStatus(true);
    taskStatus.changeFollowDaily(followRepeatDaily.value);

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: followDelayRange.value,

    }

    //store latest settings in local storage
    localStorageWrapper.set('followSettings', JSON.stringify(config))

}

const validateFollowJustJoinedRequest = () => {

    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.followJustJoined.status || !isFollowJustJoined.value) return

    log("Validating Follow Just Joined request")

    let messages = []

    if (!followJustJoinedDelayRange.value[0] || followJustJoinedDelayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (!followJustJoinedDelayRange.value[1] || followJustJoinedDelayRange.value[1] < followJustJoinedDelayRange.value[0])
        messages.push("Invalid delay range.");

    if (!followJustJoinedLimit.value || followJustJoinedLimit.value <= 0)
        messages.push("Follow limit must be greater than 0");
    if (followJustJoinedLimit.value > 1000)
        messages.push("A maximum of 1000 limit is required.");


    validations.value.set("followJustJoined", messages);

    if (messages.length) return

    //update and send to extension to start task
    const taskData = { message: 'loadNewClosets', type: 'follow', limit: followJustJoinedLimit.value, delay: { min: followJustJoinedDelayRange.value[0], max: followJustJoinedDelayRange.value[1] }, repeat: followJustJoinedRepeatDaily.value }

    //start message listener
    window.addEventListener("message", followJustJoinedMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeFollowJustJoinedStatus(true);
    taskStatus.changeFollowJustJoinedDaily(followJustJoinedRepeatDaily.value);

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: followJustJoinedDelayRange.value,

    }

    //store latest settings in local storage
    localStorageWrapper.set('followJustJoinedSettings', JSON.stringify(config))

}

const validateFollowByUsernameRequest = () => {

    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.followByUsername.status || !isFollowByUsername.value) return

    log("Validating Follow By Username request")

    let messages = []

    if (!followByUsernameDelayRange.value[0] || followByUsernameDelayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (!followByUsernameDelayRange.value[1] || followByUsernameDelayRange.value[1] < followByUsernameDelayRange.value[0])
        messages.push("Invalid delay range.");

    if (!closetURL.value || !closetURL.value.trim() || !isValidClosetUrl(closetURL.value))
        messages.push("Invalid Closet URL.")

    if (!followByUsernameLimit.value || followByUsernameLimit.value <= 0)
        messages.push("Follow limit must be greater than 0");
    if (followByUsernameLimit.value > 1000)
        messages.push("A maximum of 1000 limit is required.");

    validations.value.set("followByUsername", messages);

    if (messages.length) return

    //update and send to extension to start task
    const taskData = { message: 'getClosetOwnerUsername', limit: followByUsernameLimit.value, delay: { min: followByUsernameDelayRange.value[0], max: followByUsernameDelayRange.value[1] }, repeat: followByUsernameRepeatDaily.value, closetURL: closetURL.value }

    //start message listener
    window.addEventListener("message", followByUsernameMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeFollowByUsernameStatus(true);
    taskStatus.changeFollowByUsernameDaily(followByUsernameRepeatDaily.value);

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: followByUsernameDelayRange.value,
        closetURL: closetURL.value
    }

    //store latest settings in local storage
    localStorageWrapper.set('followByUsernameSettings', JSON.stringify(config))
}

const requireConfirmation = (position: any, message: string) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        rejectClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        acceptClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Keep Task running',
        acceptLabel: 'Stop Task',
        header: 'Are you sure you want to stop this task?',
        message: 'Please confirm to proceed.',
        accept: () => {
            sendMessage({ message });
        }
    });
};

const upgradeSubscriptionAlert = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        acceptClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        rejectClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Cancel',
        acceptLabel: 'Subscribe',
        header: 'Upgrade Subscription',
        message: 'Upgrade subscription to enable this feature.',
        accept: () => {
            tabView.changeActiveTab(2)
        }
    });
};

//This will handle messages sent from the extension to the web server
const followMessagesHandler = (event: any) => {
    if (event.data.message == "followTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeFollowStatus(false)
            taskStatus.changeFollowDaily(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", followMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartFollowTask") {

        //validations.value.set("follow", [event.data.error]);
        taskStatus.changeFollowStatus(false);
        taskStatus.changeFollowDaily(false);
        //stop message listener until the task is started again
        window.removeEventListener("message", followMessagesHandler);
    }
}

//This will handle messages sent from the extension to the web server
const followJustJoinedMessagesHandler = (event: any) => {
    if (event.data.message == "followJustJoinedTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeFollowJustJoinedStatus(false)
            taskStatus.changeFollowJustJoinedDaily(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", followJustJoinedMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartFollowJustJoinedTask") {

        //validations.value.set("followJustJoined", [event.data.error]);
        taskStatus.changeFollowJustJoinedStatus(false);
        taskStatus.changeFollowJustJoinedDaily(false);
        //stop message listener until the task is started again
        window.removeEventListener("message", followJustJoinedMessagesHandler);
    }
}

//This will handle messages sent from the extension to the web server
const followByUsernameMessagesHandler = (event: any) => {
    if (event.data.message == "followByUsernameTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeFollowByUsernameStatus(false)
            taskStatus.changeFollowByUsernameDaily(false);
            //stop message listener until the task is started again
            window.removeEventListener("message", followByUsernameMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartFollowByUsernameTask") {

        //validations.value.set("followByUsername", [event.data.error]);
        taskStatus.changeFollowByUsernameStatus(false);
        taskStatus.changeFollowByUsernameDaily(false);
        //stop message listener until the task is started again
        window.removeEventListener("message", followByUsernameMessagesHandler);
    }
}

</script>

