import { defineStore } from "pinia";
import { ref } from "vue";

export const useActiveTab = defineStore("ActiveTab", () => {
    const activeTab = ref(0);

    const changeActiveTab = (index: number) => {
        activeTab.value = index;
    }

    return { activeTab, changeActiveTab }
})