<template>
    <div v-if="isSolvingCaptchaFailed">
        <Message class="p-0 mb-1 bg-white" severity="error" :closable="false">
            {{ captchaErrorMessage }}
        </Message>
        <Message class="p-0 mb-1 bg-white" severity="warn" :closable="false">
            <div>You can solve the captcha manually and then click <a class="text-blue-500 cursor-pointer hover:underline"
                    @click="resetCaptchaCheck">resume task</a> to resume your current tasks.</div>
        </Message>
    </div>
    <div v-else-if="isCaptchaDetected">
        <Message class="p-0 mb-1 bg-white" severity="warn" :closable="false">
            A Captcha has been detected. PoshPop is solving the captcha and will resume the remaining tasks.
        </Message>
    </div>
    <div v-if="subscriptionMessages.size">
        <div class="flex w-full cursor-pointer" v-for="[type, message] of subscriptionMessages.entries()" :key="message">
            <Message class="w-full p-0 mb-1 bg-white" severity="warn" icon="text-base" :closable=false>
                You are using a feature that is available for subscribers only.
                <a class="text-blue-500 cursor-pointer hover:underline" @click="redirectToSubscriptionPage">Subscribe
                    now</a> to access this feature.
                <div class="flex underline mt-2 text-blue-500 justify-end cursor-pointer text-sm"
                    @click="hideSubscriptionMessage(type)">Hide</div>
            </Message>
        </div>
    </div>
    <div v-if="errorMessages.size">
        <div class="flex w-full cursor-pointer" v-for="[type, message] of errorMessages.entries()" :key="message"
            @click="hideErrorMessage(type)">
            <Message class="w-full p-0 mb-1 bg-white" severity="error" icon="text-base" :closable=false>
                {{ message }}
                <div class="flex underline mt-2 text-blue-500 justify-end cursor-pointer text-sm">Hide</div>
            </Message>
        </div>
    </div>
    <div v-if="warnMessages.size">
        <div class="flex w-full cursor-pointer" v-for="[type, message] of warnMessages.entries()" :key="message"
            @click="hideWarnMessage(type)">
            <Message class="w-full p-0 mb-1 bg-white" severity="warn" icon="text-base" :closable=false>
                {{ message }}
                <div class="flex underline mt-2 text-blue-500 justify-end cursor-pointer text-sm">Hide</div>
            </Message>
        </div>
    </div>
</template>

<script setup lang="ts">
import { sendMessage, log } from '@/utils/index';
import Message from 'primevue/message';
import { onMounted, onUnmounted, ref } from 'vue';

const errorMessages = ref<Map<string, string>>(new Map())
const warnMessages = ref<Map<string, string>>(new Map())
const captchaErrorMessage = ref<string>('');

const isCaptchaDetected = ref<boolean>(false);
const isSolvingCaptchaFailed = ref<boolean>(false);
const subscriptionMessages = ref<Map<string, string>>(new Map())
const messageLifeTime = 10000

const emit = defineEmits(['changeActiveTab'])

onMounted(() => {
    //reset listeners to prevent duplicate listeners
    window.addEventListener("message", messagesHandler);
    errorMessages.value = new Map()
    subscriptionMessages.value = new Map()
})

onUnmounted(() => {
    //reset listeners to prevent duplicate listeners
    window.removeEventListener('message', messagesHandler);
})

const redirectToSubscriptionPage = () => {
    emit('changeActiveTab', 2)
}

//hide message on click
const hideErrorMessage = (taskType: string) => {
    errorMessages.value.delete(taskType)
}

//hide message on click
const hideWarnMessage = (taskType: string) => {
    warnMessages.value.delete(taskType)
}

//hide message on click
const hideSubscriptionMessage = (taskType: string) => {
    subscriptionMessages.value.delete(taskType)
}

//show warning messages
const displayWarnMessage = (data: any) => {
    if (data.resolved) {
        warnMessages.value.delete(data.taskType)
        return;
    }

    const key = data.taskType ?? "system-message"

    warnMessages.value.set(key, data.content)
}

const resetCaptchaCheck = () => {
    log("reseting captcha status");
    sendMessage({ message: "resetCaptchaCheck" })
    isCaptchaDetected.value = false
    isSolvingCaptchaFailed.value = false
}

//This will handle messages sent from the extension to the web server
const messagesHandler = (event: any) => {
    if (event.data.message == "generalError") {
        if (event.data.resolved) {
            errorMessages.value.delete(event.data.taskType)
            return;
        }

        errorMessages.value.set(event.data.taskType, event.data.error)

        //TODO: uncomment timeout to hide non critical messages after a few seconds
        if (!event.data.critical) {
            // setTimeout(() => {
            //     hideMessage(event.data.taskType)
            // }, messageLifeTime)
        }
    }
    else if (event.data.message == "system-message") {
        displayWarnMessage(event.data)
    }
    else if (event.data.message == "captchaDetected") {

        isCaptchaDetected.value = !!event.data.status
        isSolvingCaptchaFailed.value = false
    }
    else if (event.data.message == "failed-to-solve-captcha") {

        isSolvingCaptchaFailed.value = true
        captchaErrorMessage.value = event.data.error
    }
    else if (event.data.message == "subscription-plan-error") {
        //In the future can be used to display multiple subscription messages. Currently all are grouped as one
        subscriptionMessages.value.set("subscription", " This feature is available for subscribers only. Please subscribe to access this feature.")
    }

}

defineExpose({
    displayWarnMessage
})
</script>

<style>
.p-message-text {
    width: 100%
}
</style>