import { currentUserVersion } from '@/globals/env'
import { isSupportedSite, sendMessage } from '@/utils'

type Callback = (value: string | null) => void

class LocalStorageWrapper {
  private messageId: number
  private callbacks: { [key: string]: Callback }
  static instance: LocalStorageWrapper
  static isOriginLocalStorageReleased = true

  private constructor() {
    this.messageId = 0
    this.callbacks = {}

    window.addEventListener('message', (event) => this.handleMessage(event))
  }

  public static getInstance(): LocalStorageWrapper {
    if (!LocalStorageWrapper.instance) {
      LocalStorageWrapper.instance = new LocalStorageWrapper()
    }

    return LocalStorageWrapper.instance
  }

  private generateMessageId(): string {
    return `msg_${this.messageId++}`
  }

  private handleMessage(event: MessageEvent) {
    if (!isSupportedSite(event.origin)) return

    const { data } = event

    if (!data.message || data.message !== 'access-storage-response') return

    if (data && data.messageId && this.callbacks[data.messageId]) {
      this.callbacks[data.messageId](data.value)
      delete this.callbacks[data.messageId]
    }
  }

  public get(storageField: string): Promise<string | null> {
    //check if older extension version and use normal localStorage in that case
    if (!LocalStorageWrapper.isOriginLocalStorageReleased || !currentUserVersion) {
      return Promise.resolve(localStorage.getItem(storageField))
    }

    return new Promise((resolve) => {
      const messageId = this.generateMessageId()
      this.callbacks[messageId] = resolve

      sendMessage({ message: 'access-storage', action: 'get', storageField, messageId })
    })
  }

  public set(storageField: string, value: string): void {
    //check if older extension version and use normal localStorage in that case
    if (!LocalStorageWrapper.isOriginLocalStorageReleased || !currentUserVersion) {
      localStorage.setItem(storageField, value)
      return
    }
    sendMessage({ message: 'access-storage', action: 'set', storageField, value })
  }

  public remove(storageField: string): void {
    //check if older extension version and use normal localStorage in that case
    if (!LocalStorageWrapper.isOriginLocalStorageReleased || !currentUserVersion) {
      localStorage.removeItem(storageField)
      return
    }
    sendMessage({ message: 'access-storage', action: 'delete', storageField })
  }
}

export default LocalStorageWrapper
